export const BASE_FINANCIAL_STATUS = {
  INCOME_STATUS: 'Pagado',
  DEBIT_STATUS: 'Adeudo pendiente de pago',
};

export const ACTIONS_GROUP = {
  ASSIGN: 1,
  REASSIGN: 2,
  PASS: 3,
  FAIL: 4,
  UNASSIGN: 5,
};
