import { getItem, postItem, putItem } from "../../../api/api-methods";

export const fetchDebtsByStudent = async ({ student, institution, status, start, end, pagination }) => {
  const encodedAlumno = encodeURIComponent(student);
  const encodedInstitucion = encodeURIComponent(institution);
  
  const limitFilter = pagination.limit !== null ? `&limit=${pagination.limit}` : '';
  const offsetFilter = pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';

  let URL = `/app-administracion/filters/adeudos?alumno=${encodedAlumno}&institucion_educativa=${encodedInstitucion}&estatus=${status}&estatus_sistema=true${offsetFilter}${limitFilter}`;
  
  if (start && end) URL += `&fecha_vencimiento=${start},${end}`;

  try {
    const responseData = await getItem(URL);
    return { data: responseData.results, count: responseData.count };
  } catch (error) {
    console.error('Error al obtener adeudos por alumno.', error);
  }
}


export const postDebt = async (debt) => {
  const URL = '/app-administracion/adeudos'
  try {
    const responseData = await postItem(URL, {
      ...debt,
      estatus: debt.estatus_adeudo
    })
    return responseData
  } catch (error) {
    console.error('Error al crear adeudo.', error);
  }
}

export const putDebt = async (debt) => {
  try {
    const { id } = debt;
    const response = await putItem(
      `/app-administracion/adeudos/${id}`,
      debt
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el adeudo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar adeudo. ' + error,
    };
  }
};