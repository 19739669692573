import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';
export const fetchIncomeCategories = async ({
  institutionId = null,
  data = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/categoria-ingreso?'.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las categorias de ingreso. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener categorias de ingreso. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener categorias de ingreso. ' + error,
      count: 0,
    };
  }
};

export const fetchIncomeCategory = async (categoryId) => {
  try {
    const response = await getItem(
      `/app-administracion/categoria-ingreso/${categoryId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener categoria de ingreso por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar obtener categoria de ingreso por id. ' + error,
    };
  }
};

export const fetchPaymentMethods = async ({
  institutionId = null,
  data = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/forma-de-pago?'.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las formas de pago. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener formas de pago. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener formas de pago. ' + error,
      count: 0,
    };
  }
};

export const fetchPaymentMethod = async (paymentMethodId) => {
  try {
    const response = await getItem(
      `/app-administracion/forma-de-pago/${paymentMethodId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener forma de pago por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener forma de pago por id. ' + error,
    };
  }
};

export const fetchFiltersWithData = async (
  model,
  {
    institutionId = null,
    data = null,
    systemStatus = null,
    limit = 100,
    offset = 0,
  }
) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      `/app-administracion/filters/${model}?`.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: `No se pudieron obtener ${model}. ${e}`,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error(`Error al obtener ${model}`, error);
    return {
      ok: false,
      message: `Error al intentar obtener ${model}. ${error}`,
      count: 0,
    };
  }
};

export const fetchAccounts = async ({
  institutionId = null,
  accountName = null,
  accountNumber = null,
  bankId = null,
  authorId = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const accountNameFilter =
      accountName !== null ? `&nombre_cuenta=${accountName}` : '';
    const accountNumberFilter =
      accountNumber !== null ? `&numero_cuenta=${accountNumber}` : '';
    const bankIdFilter = bankId !== null ? `&banco=${bankId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/cuenta?'.concat(
        institutionFilter,
        accountNameFilter,
        accountNumberFilter,
        bankIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las cuentas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener cuentas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener cuentas. ' + error,
      count: 0,
    };
  }
};

export const fetchAccount = async (accountId) => {
  try {
    const response = await getItem(`/app-administracion/cuenta/${accountId}`);
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e ? e : detail ? detail : 'Error al obtener cuenta por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener cuenta por id. ' + error,
    };
  }
};

export const fetchEducationalInstitutions = async ({
  tradename = null,
  businessName = null,
  taxDataId = null,
  branchNumber = null,
  userNumber = null,
  activationKey = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const systemStatusFilter =
      systemStatus !== null ? `estatus_sistema=${systemStatus}` : '';
    const tradenameFilter =
      tradename !== null ? `&nombre_comercial=${tradename}` : '';
    const businessNameFilter =
      businessName !== null ? `&razon_social=${businessName}` : '';
    const taxDataIdFilter =
      taxDataId !== null ? `&datos_fiscales=${taxDataId}` : '';
    const branchNumberFilter =
      branchNumber !== null ? `&num_sucursales=${branchNumber}` : '';
    const userNumberFilter =
      userNumber !== null ? `&num_usuarios=${userNumber}` : '';
    const activationKeyFilter =
      activationKey !== null ? `&clave_activacion=${activationKey}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/institucion-educativa?'.concat(
        systemStatusFilter,
        tradenameFilter,
        businessNameFilter,
        taxDataIdFilter,
        branchNumberFilter,
        userNumberFilter,
        activationKeyFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener instituciones educativas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener instituciones educativas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener instituciones educativas. ' + error,
      count: 0,
    };
  }
};

export const fetchEducationalInstitution = async (institutionId) => {
  try {
    const response = await getItem(
      `/app-personas/institucion-educativa/${institutionId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener institucion educativa por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar obtener institucion educativa por id. ' + error,
    };
  }
};

export const fetchDebts = async ({
  institutionId = null,
  debitName = null,
  studentId = null,
  discountApplied = null,
  coinId = null,
  statusId = null,
  overdueDate = null,
  penaltyId = null,
  authorId = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionIdFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const debitNameFilter =
      debitName !== null ? `&nombre_adeudo=${debitName}` : '';
    const studentIdFilter = studentId !== null ? `&alumno=${studentId}` : '';
    const discountAppliedFilter =
      discountApplied !== null ? `&descuento_aplicado=${discountApplied}` : '';
    const coinIdFilter = coinId !== null ? `&moneda=${coinId}` : '';
    const statusIdFilter = statusId !== null ? `&estatus=${statusId}` : '';
    const overdueDateFilter =
      overdueDate !== null ? `&fecha_vencimiento=${overdueDate}` : '';
    const penaltyIdFilter =
      penaltyId !== null ? `&penalizacion=${penaltyId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/adeudos?'.concat(
        institutionIdFilter,
        debitNameFilter,
        studentIdFilter,
        discountAppliedFilter,
        coinIdFilter,
        statusIdFilter,
        overdueDateFilter,
        penaltyIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener adeudos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener adeudos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener adeudos. ' + error,
      count: 0,
    };
  }
};

export const fetchIncomes = async ({
  institutionId = null,
  studentId = null,
  debitId = null,
  categoryId = null,
  penaltyApplied = null,
  discountApplied = null,
  paymentMethodId = null,
  destinationAccountId = null,
  coinId = null,
  statusId = null,
  penaltyId = null,
  authorId = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionIdFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const studentIdFilter = studentId !== null ? `&alumno=${studentId}` : '';
    const debitIdFilter = debitId !== null ? `&adeudo=${debitId}` : '';
    const categoryIdFilter =
      categoryId !== null ? `&categoria=${categoryId}` : '';
    const penaltyAppliedFilter =
      penaltyApplied !== null ? `&penalizacion_aplicada=${penaltyApplied}` : '';
    const discountAppliedFilter =
      discountApplied !== null ? `&descuento_aplicado=${discountApplied}` : '';
    const paymentMethodIdFilter =
      paymentMethodId !== null ? `&forma_de_pago=${paymentMethodId}` : '';
    const destinationAccountIdFilter =
      destinationAccountId !== null
        ? `&cuenta_destino=${destinationAccountId}`
        : '';
    const coinIdFilter = coinId !== null ? `&moneda=${coinId}` : '';
    const statusIdFilter = statusId !== null ? `&estatus=${statusId}` : '';
    const penaltyIdFilter =
      penaltyId !== null ? `&penalizacion=${penaltyId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/ingreso?'.concat(
        institutionIdFilter,
        studentIdFilter,
        debitIdFilter,
        categoryIdFilter,
        penaltyAppliedFilter,
        discountAppliedFilter,
        paymentMethodIdFilter,
        destinationAccountIdFilter,
        coinIdFilter,
        statusIdFilter,
        penaltyIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los ingresos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener ingresos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener ingresos. ' + error,
      count: 0,
    };
  }
};

/**
 * Crea un ingreso
 * @param {Object} income objeto con los datos del ingreso a crear
 * @returns
 */
export const postIncome = async (income) => {
  try {
    const response = await postItem('/app-administracion/ingreso', income);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear ingreso. ' + error,
    };
  }
};

/**
 * Actualiza un ingreso
 * @param {Object} income objeto con los datos del ingreso a actualizar. Debe incluir id
 * @returns
 */
export const putIncome = async (income) => {
  try {
    const { id } = income;
    const response = await putItem(`/app-administracion/ingreso/${id}`, income);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar ingreso. ' + error,
    };
  }
};

/**
 * Elimina un ingreso
 * @param {Number} id id del ingreso a eliminar
 * @returns
 */
export const delIncome = async (id) => {
  try {
    const response = await deleteItem('/app-administracion/ingreso', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: 'Incomeo eliminado exitosamente' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar ingreso. ' + error,
    };
  }
};

/**
 * Actualiza un adeudo
 * @param {Object} debt objeto con los datos del adeudo a actualizar. Debe incluir id
 * @returns
 */
export const putDebt = async (debt) => {
  try {
    const { id } = debt;
    const response = await putItem(`/app-administracion/adeudos/${id}`, debt);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el adeudo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar adeudo. ' + error,
    };
  }
};

export const increaseAccountBalance = async ({
  id_cuenta,
  monto,
  id_autor,
}) => {
  try {
    const url = '/app-administracion/helpers/crear-aumentar-balance-cuenta';
    const response = await postItem(url, {
      id_cuenta,
      monto,
      id_autor,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo aumentar al balance de cuenta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar aumentar el balance de cuenta. ' + error,
    };
  }
};
export const decreaseAccountBalance = async ({
  id_cuenta,
  monto,
  id_autor,
}) => {
  try {
    const url = '/app-administracion/helpers/crear-disminuir-balance-cuenta';
    const response = await postItem(url, {
      id_cuenta,
      monto,
      id_autor,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo decremetar al balance de cuenta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar decrementar el balance de cuenta. ' + error,
    };
  }
};

export const sendProofOfIncome = async (incomeId) => {
  try {
    const url = '/app-administracion/helpers/enviar-comprobante-ingreso';
    const response = await postItem(url, {
      id_ingreso: incomeId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo enviar el comprobante de ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar enviar el comprobante de ingreso. ' + error,
    };
  }
};

export const postCreateIncomeBill = async (incomeId) => {
  try {
    const url = '/app-facturacion/create-factura-ingreso';
    const response = await postItem(url, {
      id_ingreso: incomeId,
    });
    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudo crear la factura del ingreso. ' +
          (response.e || response.error),
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear la factura del ingreso. ' + error,
    };
  }
};
