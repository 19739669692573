import { getItem, postItem, putItem } from "../../../api/api-methods";
import { fetchPostAssignSubjectToStudent } from "../../employees/helpers/actionsGroupsOptions";
import { putStudent } from "../../students/helpers/utilsStudent";

export const updateTuitionDebits = async (studentObj, schoolCycleId, user) => {
  const debtsResponse = await postItem(
    '/app-administracion/helpers/get-adeudos-colegiatura-por-alumno-por-ciclo-escolar',
    {
      id_alumno: studentObj.id,
      id_ciclo_escolar: schoolCycleId,
    }
  );
  for (const debt of debtsResponse.resultado) {
    const promptPayDiscount = parseFloat(0);
    const subtotal = parseFloat(studentObj.colegiatura.monto);
    const taxes = calculateTaxes(subtotal, 0.0);
    const totalWithTaxes = calculateTotalWithTaxes(subtotal, taxes);
    const discount = calculateDiscount(
      totalWithTaxes,
      promptPayDiscount
    );
    const debtToUpdate = {
      ...debt,
      comentarios: studentObj.colegiatura.nombre_colegiatura,
      alumno: studentObj,
      moneda: debt.moneda.id,
      penalizacion: studentObj.penalizacion,
      institucion_educativa: debt.institucion_educativa.id,
      descuento_pronto_pago: studentObj.descuento,
      estatus: studentObj.id_estatus_adeudo,
      sub_total: subtotal,
      total_impuestos: taxes,
      total_descuento: discount,
      total_adeudo: totalWithTaxes,
      autor: user,
    };
    const responseData = await putItem(
      `/app-administracion/adeudos/${debt.id}`,
      debtToUpdate
    );
    return responseData
  }
}

export const generateSchoolYearDebts = async (debtsObj) => {
  try {
    const responseData = await postItem(
      '/app-administracion/helpers/crear-adeudos-ciclo-escolar',
      debtsObj
    );
    if (responseData.e && responseData.e.length > 0) {
      const errors = responseData.e.join(', ');
      return { ok: false, data: errors }
    }
    return { ok: true, data: responseData }
  } catch (error) {
    console.error('Error al intentar crear adeudos del ciclo escolar');
  }
}

export const calculateDebtsObject = async (studentObj, studentID, startDate, endDate, user ) => {
  console.log({studentObj});
  const tuitionRes = await getItem(`app-personas/colegiatura/${studentObj.colegiatura.id}`)
  studentObj.colegiatura = tuitionRes

  const promptPayDiscount = parseFloat(0);
  const subtotal = parseFloat(studentObj.colegiatura.monto);
  if (isNaN(promptPayDiscount) || isNaN(subtotal)) return 0;

  const taxes = calculateTaxes(subtotal, 0.0);
  const totalWithTaxes = calculateTotalWithTaxes(subtotal, taxes);
  if (isNaN(totalWithTaxes) || isNaN(promptPayDiscount))
    return 0;

  const discount = calculateDiscount(
    totalWithTaxes,
    promptPayDiscount
  );
  return {
    nombre_adeudo: `${studentObj.colegiatura.nombre_colegiatura} ${startDate} - ${endDate}`,
    fecha_inicio_ciclo: startDate,
    fecha_fin_ciclo: endDate,
    dias_de_gracia: 5,
    id_alumno: studentID,
    comentarios: studentObj.colegiatura.nombre_colegiatura,
    sub_total: subtotal,
    total_impuestos: taxes,
    total_descuento: discount,
    total_adeudo: totalWithTaxes,
    descuento_aplicado: false,
    id_moneda: 1,
    expirado: false,
    id_institucion_educativa: studentObj.institucion_educativa.id,
    id_autor: user,
  };
}

export const assignSubjects = async (studentObj, studyPlanObj, institutionId) => {
  const assignedSubjects = [];
  for (const subject of studyPlanObj.materias_curriculares) {
    try {
      const { ok, data } = await fetchPostAssignSubjectToStudent(studentObj.id, subject.id, institutionId);
      if (ok && data) {
        assignedSubjects.push(subject.id);
      }
    } catch (error) {
      console.error(`Error assigning subject ${subject.id} to student ${studentObj.id}:`, error);
    }
  }

  try {
    const { ok, data } = await putStudent({
      ...studentObj,
      materias: [...new Set([...assignedSubjects])],
      datos_personales: studentObj.datos_personales.id,
      institucion_educativa: studentObj.institucion_educativa.id,
      tutores: studentObj.tutores.map(e => e.id),
      usuario: studentObj.usuario.id,
      sub_nivel_escolar: 'Example',
      nivel_escolar: 'Example',
      colegiatura: studentObj.colegiatura.id
    }, studentObj.id);
    if (!ok) return { ok: false }
    return { ok: true, data }
  } catch (error) {
    console.error(`Error updating student ${studentObj.id}:`, error);
  }
}


export const calculateTaxes = (value, tax) => {
  return value * tax;
}
export const calculateDiscount = (value, discount) => {
  return value * discount;
}
export const calculateTotalWithTaxes = (value, taxes) => {
  return value + taxes;
}