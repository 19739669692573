<template>
  <section>
    <!-- <kn-select
      label="Institución educativa"
      v-model="model.id_institucion_educativa"
      :rules="[rules.required]"
      :items="institutions"
      item-text="nombre_comercial"
      item-value="id"
    /> -->
    <kn-select
      label="Nivel educativo"
      v-model="model.nivel_educativo"
      :rules="[rules.required]"
      :items="educationlevels"
      item-text="nombre"
      item-value="id"
    />
    <kn-text-field
      label="Número credencial"
      v-model="model.numero_credencial_alumno"
      :rules="[]"
      :isMandatory="false"
    />
  </section>
</template>

<script>
import { getItem } from '../../../api/api-methods';
import { validationFormMixin } from '../../shared/mixins/validationFormMixin';
import KnSelect from '../../shared/components/KnSelect.vue';
import KnTextField from '../../shared/components/KnTextField.vue';
import { mapState } from 'vuex';

export default {
  components: { KnSelect, KnTextField },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      row: null,
      institutions: [],
      educationlevels: [],
    };
  },
  computed: {
    ...mapState(['institutionId']),
  },
  async created() {
    const institutionRes = await getItem(
      `/app-personas/institucion-educativa?estatus_sistema=${true}`
    );
    const educationlevelsRes = await getItem(
      `/app-personas/filters/nivel-educativo?limit=15&estatus_sistema=${true}&institucion_educativa=${
        this.institutionId
      }`
    );
    this.institutions = institutionRes.results;
    this.educationlevels = educationlevelsRes.results;
  },
};
</script>

<style></style>
