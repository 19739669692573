import { api } from '../../../api/axios-base'
export const addressUtilsMixin = {
  methods: {
    async fetchAddresById(addressId, institutionId) {
      try {
        const response = await api.get(`/personas/api/direccion-by-ie/${addressId}/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta direccion por id:', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener direccion por id', error);
      }
    },
    async fetchZipById(zipId) {
      try {
        const response = await api.get(`/personas/api/codigo-postal-all/${zipId}/`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener codigo postal por id');
      }
    },
    fullAddressWithZipObj({calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es,
      codigo_postal: cp
    }) {
      const fullAddress = `${ca} ${ne}${ni ? ' '+ni : ''}, Col. ${col}, ${ci}, ${es}. CP. ${cp.codigo_postal}`
      return fullAddress
    }
  }
}