import { mapState } from 'vuex';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import { getItem } from '../../../../api/api-methods';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { postDebt, putDebt } from '../../helpers/utilsDebits';
import {
  fetchStudents,
  fetchGroups,
} from '../../../employees/helpers/reportCardOptions';
import { fetchDebts } from '../../../income/helpers/incomeOptions';
import {
  fetchStudentByName,
  findGroupAssignedToStudent,
  postActivateEarlyStimulation,
  postAssignStudenToGroup,
  postDeactivateEarlyStimulation,
  postRemoveStudenOfGroup,
} from '../../helpers/utilsStudent';
import { months } from '../../../shared/helpers/dateUtils';

import {
  can,
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import { Actions } from '../../../shared/helpers/permissionContants';

const actionConsts = {
  ASSIGN: 1,
  REASSIGN: 2,
  PASS: 3,
  FAIL: 4,
  UNASSIGN: 5,
};

export default {
  name: 'KnFormEarlyStimulation',
  components: {
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnLocalAlert,
    KnSelect,
    KnTabs,
    KnTextField,
  },
  mixins: [validationFormMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      valueDeterminate: 33,
      routerName: 'Estimulacion Temprana',
      resource: 'alumno',
      row: null,
      tabs: [
        { name: 'Estimulación temprana', value: 33 },
        // { name: 'Datos del tutor', value: 66 },
        // { name: 'Datos fiscales', value: 100 },
      ],
      studentId: null,
      selectedStudent: null,
      students: [],
      groupActions: [
        {
          text: 'Reasignar - corrección de colegiatura',
          value: actionConsts.REASSIGN,
        },
        {
          text: 'Reasignar - grupo diferente (generar nuevos adeudos)',
          value: actionConsts.PASS,
        },
        // { text: 'Recursar', value: actionConsts.FAIL },
        { text: 'Desasignar', value: actionConsts.UNASSIGN },
      ],
      actions: { ...actionConsts },
      selectedGroupAction: null,
      groups: [],
      tuition: [],
      promptPaymentDiscounts: [],
      penalties: [],
      debtStatus: [],
      openChargeInfo: false,
      selectedGroup: null,
      selectedBkGroup: null,
      selectedTuition: null,
      selectedPromptPaymentDiscount: null,
      selectedPenalty: null,
      selectedDebitStatus: null,
      entityGroup: null,
      startDate: null,
      endDate: null,
      studentDebts: [],
      wildcardStudent: null,
      wildcardStudentDebts: [],
      checkInitialDebts: true,
      searchText: '',
      days: 10,
      duration: 1,
      debitDates: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Asignar alumno' : 'Reasignar alumno';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Alumno asignado a estimulación temprana con exito!'
        : 'Alumno reasignado a estimulación temprana con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    assignGroupText() {
      return !this.hasGroup ? 'Asignar grupo' : 'Grupo';
    },
    invalidFields() {
      return this.isNewMode && this.selectedStudent === null;
    },
    studentAssignedGroup() {
      return this.selectedStudent && this.groups.length
        ? findGroupAssignedToStudent(this.selectedStudent.id, this.groups, true)
          ? findGroupAssignedToStudent(
              this.selectedStudent.id,
              this.groups,
              true
            )
          : false
        : false;
    },
    hasGroup() {
      return this.isNewMode
        ? !!this.studentAssignedGroup
        : (this.selectedStudent &&
            this.selectedStudent.is_estimulacion_temprana) ||
            !!this.studentAssignedGroup;
    },
    showGroupActions() {
      return !this.isNewMode && this.hasGroup;
    },
    showAssignGroup() {
      return (
        this.isNewMode ||
        (!this.isNewMode &&
          (!this.hasGroup ||
            (this.selectedGroupAction !== null &&
              this.selectedGroupAction !== actionConsts.UNASSIGN)))
      );
    },
    showPassGroup() {
      return (
        !this.hasGroup ||
        (this.selectedGroupAction !== null &&
          this.selectedGroupAction === actionConsts.PASS)
      );
    },
    schoolCycleText() {
      return this.selectedGroup !== null
        ? `${this.selectedGroup.ciclo_escolar.fecha_inicio} - ${this.selectedGroup.ciclo_escolar.fecha_fin}`
        : '';
    },
    tuitionText() {
      return this.selectedGroup !== null
        ? this.entity && this.entity.colegiatura
          ? `${this.entity.colegiatura.nombre_colegiatura} - $${this.entity.colegiatura.monto}`
          : 'Sin asignar'
        : 'Sin asignar';
    },
    hasEnoughPermissions() {
      return (
        canChange(this.resource) ||
        can({
          actions: [Actions.add, Actions.change],
          resource: 'adeudo',
        }) ||
        canAdd('cambiogrupo')
      );
    },
    isReadonly() {
      return !this.hasEnoughPermissions;
    },
  },
  watch: {},
  async created() {
    this.loading = true;
    this.alertText = 'Por favor, espere. Cargando...';
    this.showAlert = true;
    this.alertType = 'info';
    await this.fetchData();
    if (!this.isNewMode) {
      this.studentId = this.entity.id;
      this.fillData();
    }
    this.showAlert = false;
  },
  methods: {
    getFullName: getFullName,
    async fetchData() {
      await this.getPaginatedStudents(1);
      const { ok: okGroups, data: dataGroups } = await fetchGroups({
        institutionId: this.institutionId,
        schoolCycleId: null,
        systemStatus: true,
        limit: 100,
      });

      this.groups = okGroups ? dataGroups : [];
    },
    async getPaginatedStudents(page = 1) {
      const { ok: okStudents, data: dataStudents } = await fetchStudents({
        institutionId: this.institutionId,
        is_estimulacion_temprana: this.isNewMode ? false : null,
        systemStatus: true,
        page,
        limit: 2000,
      });
      this.students = okStudents ? dataStudents : [];
    },
    async setStudentByName() {
      // console.log('setStudentByName - this.searchText', this.searchText);

      if (
        !this.loading &&
        (this.searchText !== null || this.searchText !== '')
      ) {
        this.loading = true;
        const { ok, data } = await fetchStudentByName(this.searchText);
        if (ok && data && data.length) {
          this.students = data;
        } else {
          this.students = [];
        }
      } else {
        await this.getPaginatedStudents(1);
      }
      this.loading = false;
    },
    setStudent() {
      this.studentId = this.selectedStudent.id;
      // console.log('this.studentAssignedGroup', this.studentAssignedGroup);
    },
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    async save() {
      // TODO: validar campos
      this.loading = true;
      this.alertText = 'Cargando...';
      this.showAlert = true;
      if (this.isNewMode) {
        if (this.hasEnoughPermissions) {
          await this.assignStudent(true);
          this.completeAlert();
        } else {
          this.insufficientPermissionAlert();
        }
      } else {
        // TODO: Dependiendo de la accion de grupo seleccionada
        // ejecutar la accion correspondiente.
        // Actualizacion de colegiatura, nuevos adeudos o desasignar
        if (this.hasEnoughPermissions) {
          switch (this.selectedGroupAction) {
            case actionConsts.PASS:
            case actionConsts.REASSIGN:
              await this.reassignStudent();
              break;
            case actionConsts.UNASSIGN:
              await this.unassignStudent(true);
              break;
          }
          this.completeAlert();
        } else {
          this.insufficientPermissionAlert();
        }
      }
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.returnToTable();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.selectedStudent = null;
      this.selectedGroupAction = null;
      this.openChargeInfo = false;
      this.clearGroup();
    },
    calculateTaxes(value, tax) {
      return value * tax;
    },
    calculateDiscount(value, discount) {
      return value * discount;
    },
    calculateTotalWithTaxes(value, taxes) {
      return value + taxes;
    },
    calculateTotalWithDiscount(value, discount) {
      return value - discount;
    },
    fillData() {
      this.selectedStudent = this.entity;
      // console.log('Alumno a editar: ', this.entity);
      const group = findGroupAssignedToStudent(
        this.selectedStudent.id,
        this.groups
      );
      this.selectedGroup = group ? group : null;
      this.selectedBkGroup = this.selectedGroup;

      this.duration = group ? group.duracion_ciclo_escolar_meses : 1;
    },
    async fillGroupRelatedArrays() {
      try {
        if (
          !this.tuition.length &&
          !this.promptPaymentDiscounts.length &&
          !this.penalties.length &&
          !this.debtStatus.length
        ) {
          // console.log('Se deben llenar los arrays relacionados a grupo');
          const requests = [
            getItem(
              `/app-personas/filters/colegiatura?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
            ),
            getItem(
              `/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
            ),
            getItem(
              `/app-administracion/filters/penalizacion?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
            ),
            getItem(
              `/app-administracion/filters/estatus-adeudos?dato=Pendiente de pago&institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
            ),
          ];

          const [
            tuitionRes,
            promptPaymentDiscountsRes,
            penaltiesRes,
            debtStatusRes,
          ] = await Promise.all(requests);

          this.tuition = tuitionRes.results;
          this.promptPaymentDiscounts = promptPaymentDiscountsRes.results;
          this.penalties = penaltiesRes.results;
          this.debtStatus = debtStatusRes.results;
          this.selectedDebitStatus = this.debtStatus.length
            ? this.debtStatus[0].id
            : null;
        }
        // console.log('Se deben llenar los campos relacionados a grupo');
      } catch (error) {
        console.error(
          'Error al intentar llenar los arrays relacionados a grupo:',
          error
        );
      }
    },
    async loadGroupInfo() {
      await this.fillGroupRelatedArrays();
      this.openChargeInfo = true;
      if (this.selectedGroupAction === null) {
        this.selectedGroupAction = actionConsts.ASSIGN;
      }
      if (this.selectedGroup) {
        this.duration = this.selectedGroup.duracion_ciclo_escolar_meses;
        this.startDate = null;
        if (
          !this.isNewMode &&
          this.selectedGroupAction === actionConsts.REASSIGN &&
          !this.studentDebts.length
        ) {
          // console.log('respaldo grupo', this.selectedBkGroup);
          const { ok, data, message } = await fetchDebts({
            institutionId: this.institutionId,
            debitName: this.selectedBkGroup.nombre_grupo,
            studentId: this.selectedStudent.id,
            statusId: this.selectedDebitStatus,
            limit: 2000,
          });
          if (ok) {
            this.studentDebts = data;
            // console.log('Adeudos de alumno', this.studentDebts);
          } else {
            console.error('No se pudieron obtener los adeudos', message);
          }
        }
      }
    },
    clearGroup() {
      this.openChargeInfo = false;
      this.selectedGroupAction = null;
      this.selectedStudent = null;
      this.selectedGroup = null;
      this.selectedTuition = null;
      this.selectedPromptPaymentDiscount = null;
      this.selectedPenalty = null;
      this.selectedDebitStatus = null;
      this.startDate = null;
      this.endDate = null;
    },
    generateDates() {
      this.debitDates = [];
      // console.log('start date', this.startDate);
      if (this.startDate) {
        const iDate = new Date(this.startDate);
        const initialDate = new Date(
          iDate.getTime() + iDate.getTimezoneOffset() * 6000
        );
        const startDay = initialDate.getDate();
        const startMonth = initialDate.getMonth();
        const startYear = initialDate.getFullYear();
        // console.log('Initial date: ', initialDate);
        // console.log('startDay', startDay);
        // console.log('startMonth', startMonth);
        // console.log('startYear', startYear);
        let i = 1;
        while (i <= this.duration) {
          const overdueDate = new Date(startYear, startMonth + i, startDay);
          const promptDate = new Date(startYear, startMonth + i, startDay - 10);
          const month = months[overdueDate.getMonth() - 1];
          // console.log('Mes: ', month);
          const overdueZero = overdueDate.getMonth() < 10 ? '0' : '';
          const promptZero = promptDate.getMonth() < 10 ? '0' : '';
          const item = {
            mes_vencimiento: month,
            year_vencimiento: overdueDate.getFullYear(),
            fecha_vencimiento: `${overdueDate.getFullYear()}-${overdueZero}${overdueDate.getMonth()}-${overdueDate.getDate()}`,
            fecha_pronto_pago: `${promptDate.getFullYear()}-${promptZero}${promptDate.getMonth()}-${promptDate.getDate()}`,
          };
          this.debitDates.push(item);
          i++;
        }
        // console.log('Fechas', this.debitDates);
      }
    },
    async createDebts() {
      try {
        // TODO: Obtener o hacer que el usuario seleccione impuestos y descuentos
        for (const debtDate of this.debitDates) {
          const debit = {
            nombre_adeudo: `Colegiatura ${debtDate.mes_vencimiento} ${debtDate.year_vencimiento} ${this.selectedGroup.nombre_grupo}`, //'Col Julio 2024 ET - Nivel 2',
            alumno: this.selectedStudent.id,
            comentarios: `Colegiatura ${debtDate.mes_vencimiento} ${debtDate.year_vencimiento} ${this.selectedGroup.nombre_grupo}. Se debera pagar a más tardar ${debtDate.fecha_vencimiento} para no incurrir en penalizaciones`,
            sub_total: this.selectedTuition.monto,
            total_impuestos: 0,
            total_descuento: 0,
            total_adeudo: this.selectedTuition.monto,
            descuento_aplicado: false,
            moneda: 1, // TODO: Obtener del backend
            estatus_adeudo: this.selectedDebitStatus,
            estatus: this.selectedDebitStatus,
            expirado: false,
            fecha_vencimiento: debtDate.fecha_vencimiento,
            fecha_pronto_pago: debtDate.fecha_pronto_pago,
            penalizacion: this.selectedPenalty,
            descuento_pronto_pago: this.selectedPromptPaymentDiscount.id,
            institucion_educativa: this.institutionId,
            autor: this.userData.id,
            estatus_sistema: true,
          };
          const response = await postDebt(debit);
          if (response.e) {
            this.errors.push(response.e);
          } else {
            // console.log('Response', response);
            this.alertText = 'Adeudo creado exitosamente';
          }
        }
      } catch (error) {
        console.error('Error al intentar crear adeudos', error);
      }
    },
    isDifferentGroup() {
      return this.selectedGroup.id !== this.selectedBkGroup.id;
    },
    isDifferentTuition(currentMount) {
      return currentMount !== this.selectedTuition.monto;
    },
    replaceDebitText(text) {
      return this.isDifferentGroup()
        ? text.replace(
            this.selectedBkGroup.nombre_grupo,
            this.selectedGroup.nombre_grupo
          )
        : text;
    },
    replaceDebitMount(mount) {
      return this.isDifferentTuition(mount)
        ? this.selectedTuition.monto
        : mount;
    },
    async updateDebts() {
      try {
        for (const debit of this.studentDebts) {
          const debtToUptade = {
            ...debit,
            alumno: debit.alumno.id,
            moneda: debit.moneda.id,
            estatus: debit.estatus.id,
            penalizacion: debit.penalizacion.id,
            descuento_pronto_pago: debit.descuento_pronto_pago.id,
            institucion_educativa: this.institutionId,
            autor: this.userData.id,
            nombre_adeudo: this.replaceDebitText(debit.nombre_adeudo),
            comentarios: this.replaceDebitText(debit.comentarios),
            sub_total: this.replaceDebitMount(debit.sub_total),
            total_adeudo: this.replaceDebitMount(debit.total_adeudo),
          };
          const { ok, message } = await putDebt(debtToUptade);
          if (!ok) {
            this.errors.push('No se pudo actualizar el adeudo. ' + message);
          } else {
            // console.log('Adeudo actualizado exitosamente', data);
            this.alertText = 'Adeudo actualizado exitosamente';
          }
        }
      } catch (error) {
        console.error('Error al intentar actualizar adeudos', error);
      }
    },
    async assignStudent(generateDebts = true) {
      /**
       * 1. Establecer el check de is_estimulacion_temprana
       *    del alumno a true
       * 2. Se debe asignar el alumno al grupo seleccionado
       * 3. Se deben crear los adeudos correspondientes a
       *    la duracion establecida en el grupo seleccionado
       */
      try {
        const { ok: okEarly, message: messageEarly } =
          await postActivateEarlyStimulation(this.selectedStudent.id);
        if (!okEarly) {
          this.errors.push(messageEarly);
        } else {
          const { ok: okAssign, message: messageAssign } =
            await postAssignStudenToGroup(
              this.selectedStudent.id,
              this.selectedGroup.id
            );
          if (!okAssign) {
            this.errors.push(messageAssign);
          } else if (generateDebts) {
            await this.createDebts();
          } else {
            // console.log('No se deben crear los adeudos');
          }
        }
      } catch (error) {
        console.log(
          'Error al intentar asignar alumno a grupo de estimulación temprana',
          error
        );
        this.errors.push(
          'Error al intentar asignar alumno a grupo de estimulación temprana. ' +
            error
        );
      }
    },
    async unassignStudent(deactivateEarlyStimulation = true) {
      /**
       * 1. Establecer el check de is_estimulacion_temprana
       *    del alumno a false
       * 2. Desasignar alumno del grupo indicado (el de
       *    respaldo)
       * TODO: Hay que revisar que hacer con los adeudos
       */
      try {
        if (deactivateEarlyStimulation) {
          const { ok: okEarly, message: messageEarly } =
            await postDeactivateEarlyStimulation(this.selectedStudent.id);
          if (!okEarly) {
            this.errors.push(messageEarly);
          } else {
            this.alertText = 'Estimulación temprana desactivado exitosamente';
          }
        }
        const { ok: okRemove, message: messageRemove } =
          await postRemoveStudenOfGroup(
            this.selectedStudent.id,
            this.selectedBkGroup.id
          );
        if (!okRemove) {
          this.errors.push(messageRemove);
        } else {
          this.alertText =
            'Alumno removido de estimulación temprana exitosamente';
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar desasignar alumno de estimulación temprana. ' +
            error
        );
      }
    },
    async reassignStudent() {
      /**
       * Dependiendo de la acción de grupo seleccionada
       * se deben realizar diferentes pasos.
       * REASSIG - Reasignar - cambio de colegiatura
       * 1. Revisar si hay cambio de grupo, si lo hay,
       *    desasignar el de respaldo y asignar el nuevo
       * 2. Actualizar los adeudos. Si hay cambio de grupo
       *    se debe actualizar el nombre y los comentarios.
       *    Si hay cambio de monto de la colegiatura, se
       *    debe actualizar el sub_total y el total del
       *    adeudo
       * PASS - Reasignar - nuevo grupo (generar adeudos)
       * 1. Desasignar grupo anterior
       * 2. Asignar alumno a grupo seleccionado, con la
       *    opcion de generar adeudos
       */
      try {
        if (this.selectedGroupAction === actionConsts.REASSIGN) {
          if (this.isDifferentGroup()) {
            await this.unassignStudent(false);
            await this.assignStudent(false);
          }
          await this.updateDebts();
        } else if (this.selectedGroupAction === actionConsts.PASS) {
          await this.unassignStudent(false);
          await this.assignStudent(true);
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar reasignar alumno de estimulación temprana. ' +
            error
        );
      }
    },
  },
};
