import { getItem, postItem } from "../../../api/api-methods";

export const financialStatusUtilsMixin = {
  methods: {
    async getOrCreateStatusIncome(name, user, institution) {
      try {
        const statusIncome = await getItem(`/app-administracion/filters/estatus-ingreso?dato=${name}&institucion_educativa=${institution}&estatus_sistema=true&limit=20`);
        if (statusIncome.results.length > 0) {
          return statusIncome.results[0];
        } else {
          const createdStatusIncome = await postItem('/app-administracion/estatus-ingreso', {
            dato: name,
            autor: user,
            estatus_sistema: true,
            institucion_educativa: institution
          });
          return createdStatusIncome;
        }
      } catch (error) {
        console.error('Error in getOrCreateStatusIncome:', error);
      }
    },
    async getOrCreateStatusDebit(name, user, institution) {
      try {
        const statusDebit = await getItem(`/app-administracion/filters/estatus-adeudos?dato=${name}&institucion_educativa=${institution}&estatus_sistema=true&limit=20`);
        if (statusDebit.results.length > 0) {
          return statusDebit.results[0];
        } else {
          const createdStatusDebit = await postItem('/app-administracion/estatus-adeudos', {
            dato: name,
            autor: user,
            estatus_sistema: true,
            institucion_educativa: institution
          });
          return createdStatusDebit;
        }
      } catch (error) {
        console.error('Error in getOrCreateStatusDebit:', error);
      }
    },
  }
}