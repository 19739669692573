export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
/**
 * Convierte una fecha, ya sea tipo string o tipo Date,
 * a formato local - ejemplo 'YYYY-MM-dd' a 'dd/MM/YYYY'
 * @param stringDate fecha como cadena
 * @param date fecha
 * @returns fecha como cadena
 */
export const formatStringDateToLocaleDate = ({
  stringDate = null,
  date = null,
}) => {
  if (stringDate !== null) {
    const dateDate = new Date(stringDate);
    const tzDate = new Date(
      dateDate.getTime() + dateDate.getTimezoneOffset() * 60000
    );
    return tzDate.toLocaleDateString('es-MX');
  }
  if (date !== null) {
    return date.toLocaleDateString('es-MX');
  }
  return '';
};

/**
 * Obtiene el primer dia de un mes
 * @param {Number} year
 * @param {Number} month
 * @returns fecha string con formato YYYY/mm/dd
 */
export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1).toISOString().substring(0, 10);
};
/**
 * Obtiene el ultimo dia de un mes
 * @param {Number} year
 * @param {Number} month
 * @returns fecha string con formato YYYY/mm/dd
 */
export const getLastDayOfMonth = (year, month) => {
  return new Date(year, month + 1, 1 - 1).toISOString().substring(0, 10);
};

// TODO: Pasar los métodos de dateUtilMixin a este archivo y usar los metodos de acá
