import { postItem } from "../../../api/api-methods";
import { fetchStudentByName } from "../helpers/utilsStudent";

export const WildcardFetcher = {
  methods: {
    async getOrCreateWildcard(institution, user) {
      try {
        const { data } = await fetchStudentByName('Comodin',undefined, true, institution)
        if (data.results.length > 0) {
          // console.log(data.results[0], ' comodin');
          return data.results[0];
        } else {
          const personalData = await postItem('app-personas/datos-personales', {
            primer_nombre: 'Comodin',
            segundo_nombre: 'Comodin',
            apellido_paterno: 'Comodin',
            apellido_materno: 'Comodin',
            fecha_nacimiento: '2000-12-12',
            edad: 10,
            genero: 1,
            numero_identidad_CURP: 'PUBLICO',
            email: 'perfil_wildcard@outlook.com',
            telefono_casa: '00000000',
            telefono_movil: '00000000',
            direccion: 1,
            institucion_educativa: institution,
            estatus_sistema: true,
          })
          const wildcardUser = await postItem('app-personas/alumno', {
            usuario: user,
            numero_credencial_alumno: 'Comodin',
            datos_personales: personalData.id,
            tutores: [1],
            institucion_educativa: institution,
            estatus_sistema: true,
          })
          return wildcardUser
        }
      } catch (error) {
        console.error('Error in getOrCreateWildcard:', error);
      }
    },
  }
}